<template>
  <div class="item">
     <TitleHeader msg="代付宝申请"></TitleHeader>
     <el-row class="content">
       <el-col :span="10">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">

              <el-form-item label="法人姓名:" prop="name">
                <el-input v-model="ruleForm.name"  size="mini"></el-input>
              </el-form-item>
        
               <el-form-item label="手机号:" prop="phone">
                <el-input   v-model.number="ruleForm.phone" size="mini"></el-input>
              </el-form-item>

               <el-form-item label="身份证号:" prop="ID">
                <el-input v-model="ruleForm.ID" size="mini"></el-input>
              </el-form-item>

              <el-form-item label="邮编:" prop="postcode">
                <el-input v-model="ruleForm.postcode" size="mini"></el-input>
              </el-form-item>

              <el-form-item label="备注:" prop="leaveMsg">
                <el-input type="textarea" v-model="ruleForm.leaveMsg"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">提交申请</el-button>
              </el-form-item>
            </el-form>
       </el-col>


       <el-col :span="14" class="text-right yptCode">
          <!-- <div class="text-center"><img :src="img" alt="代付宝"></div>
          <div class="text-center tips">扫码下载代付宝(银企通)（目前只开放安卓版）</div> -->
       </el-col>
     </el-row>
  </div>
</template>

<script>
import {MemberStoreCredit} from "api/member.js"
// import Qs from 'qs'
const TitleHeader = ()=>import("components/common/TitleHeader.vue");
  export default {
    name:"Ypt",
    data(){
        let checkPhoto = (rule, value, callback) => {
          if (!value) {
            return callback(new Error("手机号不能为空"));
          }else{
              if (!Number.isInteger(value)) {
                callback(new Error("请输入手机号"));
              } else {
                if (!/^1[345678]\d{9}$/.test(value)) {
                  callback(new Error("请输入正确的手机号码!"));
                } else {
                  callback();
                }
              }
          }
        };

        let checkIdNum = (rule, value, callback)=>{
          if (!value) {
            return callback(new Error('证件号码不能为空'))
          } else if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
            return callback(new Error('证件号码不正确'))
          } else {
            callback()
          }
        };
        let checkPostCode = (rule, value, callback)=>{
          if (!value) {
            return callback(new Error('邮编不能为空'))
            //^[0-9][0-9]{5}$    
          } else if (!/^(0[1234567]|1[012356]|2[01234567]|3[0123456]|4[01234567]|5[1234567]|6[1234567]|7[012345]|8[013456])\d{4}$/.test(value)) {
            return callback(new Error('邮编不正确'))
          } else {
            callback()
          }
        };
      return{
        img:"https://3akx.com/resources/member/images/yqt.png",
        ruleForm: {
          name: '',
          phone: '',
          ID: '',
          postcode: '',
          leaveMsg:''
        },
        rules: {
          name: [
            { required: true, message: '请输入法人姓名', trigger: 'blur' },
             { min:2, max: 10, message: '法人姓名最少两个字', trigger: 'blur' }
          ],
          phone: [
            {required:true,message:"手机号必填", trigger: "blur"},
            { validator: checkPhoto, trigger: "blur" }
            ],
          ID: [
                { required: true, message: '身份证号不能为空', trigger: 'blur'},
                { validator: checkIdNum, trigger: "blur" }
          ],
          postcode: [
            { required: true, message: '请输入邮编', trigger: 'blur' },
             { validator: checkPostCode, trigger: "blur" }
          ]
        }
      };
    },
    methods:{
     submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            let params={
              name:this.ruleForm.name,
              idCard:this.ruleForm.ID,
              phone:String(this.ruleForm.phone),
              postcode:this.ruleForm.postcode,
              remark:this.ruleForm.leaveMsg,
            }

            MemberStoreCredit(params).then(data=>{
              if(data.data.code===400){
                this.$message(data.data.msg)

                }else{
                  this.$message({
                    message: data.data.msg,
                    type: 'success'
                  });
                  this.$router.push({ name: "Recharge" });
                }
            })
          } else {
            return false;
          }
        });
      }
  },
   created(){
     
    },
    components:{
       TitleHeader
    }
  }
</script>
<style lang="less" scoped>
@import '~style/index.less';
.item {
  padding-top: 20px;
  .content {
    padding-top: 36px;
    .yptCode {
      .fonts();
      color: #666;
      img {
        display: inline-block;
        width: 200px;
        height: 200px;
        border:1px dashed #eee;
      }
      .tips {
           opacity: 0.8;
           padding-top: 15px;
      }
    }
  }
   /deep/.el-button--primary{
    background-color: @theme;
    border-color:@theme;
    width: 136px;
    height: 36px;
    color: #fff;
    margin-top: 10px;
    padding-top: 10px;
}
   /deep/.el-input__inner,/deep/.el-textarea__inner{
     width: 190px;
     height: 30px;
     margin-left: 5px;
   }
}

</style>
